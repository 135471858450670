@import 'assets/styles/colors.scss';

.emptyImageContainer {
    width: 100%;
    height: 100%;
    background-color: $secondary-btn-background-color;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}