@import "assets/styles/colors.scss";

.boxContainer {
    border: 2px solid $primary-border-color;
    border-radius: 5px;
    position: relative;
    margin: 20px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;

  

    .textBoxContainer {
      width: 100%;
      :global(.ant-tabs-tab) {
        margin-right: 20px;
        color: $primary-heading-color;
        font-weight: bold;
      }
      b {
        color: $primary-heading-color;
        font-weight: bolder;
        font-size: 18px;
      }
      p {
        color: $primary-text-color;
        margin: 0;
        font-size: $primary-text-font-size;
        font-weight: bold;
      }
    }
  }