@import "assets/styles/colors.scss";

.secondaryBtn {
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: $secondary-btn-background-color !important;
  color: $secondary-btn-color !important;
  border: $secondary-btn-border;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    color: $primary-text-color;
    background-color: $secondary-btn-background-color !important;
  }
}

.two {
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #7393b3 !important;
  color: #ffffff;
  border: $secondary-btn-border;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    color: #ffffff;
    background-color: #7393b3 !important;
  }

  &:disabled {
    color: black;
    opacity: 0.50;
    cursor: not-allowed;
  }
}

.one {
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #b2beb5 !important ;
  color: #000000 !important ;
  border: $secondary-btn-border;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    color: #000000 !important ;
    background-color: #b2beb5 !important;
  }
}

.three {
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background-color: #36454F !important;
  border: $secondary-btn-border;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    color: #ffffff;
    background-color: #36454F !important;
  }
}
