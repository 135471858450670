@import "assets/styles/colors.scss";

.userListContainer {
  padding: 20px;

  .onClickName {
    cursor: pointer;
    margin: 0;

    &:hover {
      text-decoration: underline;
    }
  }

  .eyeIcon {
    color: $primary-text-color;
    cursor: pointer;
  }

  .amount {
    p {
      margin: 0px;
    }
  }

  :global(.ant-table-title) {
    padding: 0px 20px;
  }

  .customerTable {
    margin: 10px 0px;

     
  }

  .searchBoxContainer {
    padding: 10px 20px;
  }

  .actions {
    display: flex;
    flex-direction: row;
    gap: 20px;

    div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 40px;

      span {
        padding: 5px 10px;
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .searchBoxContainer {
      input {
        width: 250px !important;
      }
    }
  }
}

.modalHeading {
  
  :global(.ant-col) {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;

    h5 {
      color: $primary-heading-color;
      font-weight: bold;
      margin: 0;
    }
    p {
      color: $primary-text-color;
      font-weight: bold;
      margin: 0;
    }

    img {
      width: 40%;
      height: auto;
      margin: 10px;
    }
  }

  .docs {
    display: flex;
    flex-direction: row;
    align-items: flex-start !important;
  } 
}
