@import "assets/styles/colors.scss";

.pageContainer {
  padding: 20px;

  .cardContainer {
    background-color: $secondary-card-bg;
    border-radius: $primary-card-border-radius;
    padding: 20px;
  }

  .headerbtnContainer {
    margin-bottom: 10px;
    padding: 0px 20px;
    gap: 20px;

    button {
      background-color: $secondary-btn-background-color;
      color: $secondary-btn-color;
    }

    .disabledButton {
      opacity: 0.8;
    }
  }

  .userInfoRow {
    .userInfoCol {
      margin: 10px 0px;

      .leftUserInfoCol {
        .leftUserInfo {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          gap: 10px;

          div {
            padding: 2px 0px;

            p,
            h5 {
              margin: 0;
              font-size: 15px;
            }

            p {
              color: $primary-text-color;
              font-size: 15px;
            }

            h5 {
              color: black;
            }

            .valueRow {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
            }
          }
        }
      }

      .rightUserInfoCol {
        .countryValueRow {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          h5,
          p {
            margin: 0;
            font-size: 15px;
            color: $primary-text-color;
          }

          h5 {
            color: black;
            font-size: 15px;
          }
        }

        div {
          margin: 5px 0px;

          p,
          h5 {
            margin: 0;
            font-family: bold;
            font-size: 15px;
          }

          p {
            color: $primary-text-color;
          }

          h5 {
            color: $primary-heading-color;
          }
        }

        .addressUserInfoRight {
          display: flex;
          gap: 5px;
          div {
            display: flex;
            h5 {
              text-align: right;
              padding-right: 8px;
              font-size: 15px;
              color: black;
            }
          }
        }
      }
    }

    .auctionParticipatedCol {
      padding: 10px 0px;

      .aucPartHeader {
        justify-content: space-between;
        margin-bottom: 10px;

        div {
          h4 {
            color: black;
            margin: 0;
            font-size: 15px;
          }
        }
      }

      .aucPartValuesContainer {
        div {
          margin: 5px 0px;

          p,
          h5 {
            margin: 0;
            font-family: bold;
            font-size: 15px;
          }

          p {
            color: $primary-text-color;
          }

          h5 {
            color: $primary-heading-color;
            padding-right: 10px;
          }
        }
      }
    }
  }

  .depostManagementCard {
    background-color: $secondary-card-bg;
    border-radius: $primary-card-border-radius;
    padding: 20px;
    margin: 20px 0px;

    .depositManagementHeader {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: $primary-heading-color;
      padding-bottom: 15px;

      h4 {
        color: black;
        margin: 0;
        font-size: 15px;
      }

      span {
        font-size: 15px;
      }

      .depositBtnCol {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        gap: 10px;
        align-items: center;

        h4 {
          font-size: 15px;
        }
        img {
          cursor: pointer;
          font-size: 10px;
        }
      }
    }

    .managementValues {
      div {
        p {
          color: black;
          font-size: 15px !important;
        }

        h5 {
          color: $primary-heading-color;
          font-size: 15px;
        }
      }
    }
  }

  .historyContainer {
    padding: 20px;
    border-radius: $primary-card-border-radius;
    background-color: $secondary-card-bg;

    .historyHeader {
      margin: 20px 0px;

      h4 {
        color: black;
        margin: 0;
        font-size: 15px;
      }
    }

    .historybody {
      .historyItemRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .historyTitle {
          h5 {
            color: $primary-heading-color;
            margin: 0;
            font-size: 15px;

            span {
            }

            .seeMore {
              cursor: pointer;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }

        .historyDates {
          color: $primary-text-color;
          flex-direction: column;
          justify-content: flex-end;

          p {
            margin: 5px;
            text-align: right;
          }
        }

        .viewAttachment {
          cursor: pointer;
          font-weight: 900;

          a {
            color: $secondary-btn-background-color;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .depostManagementCard {
      .managementValues {
        div {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }

    .historyContainer {
      .historybody {
        .historyItemRow {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 40px;
          margin-bottom: -10px;

          .historyDates {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            width: 100%;
          }
        }
      }
    }
  }

  @media screen and (max-width: 400px) {
    .historyContainer {
      .historybody {
        .historyItemRow {
          .historyDates {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;

            p {
              text-align: center;
            }
          }
        }
      }
    }
  }
}

.uploadRecieptModal {
  width: 450px;
  margin: auto;
  

  .userDetailsModalForm {

    .inputWidth {
      display: flex;
      flex-direction: column;
    }

    .fileContainer {
      width: 50%;
      margin: auto;
    }


    :global(.ant-form-item-explain-error) {
      margin-bottom: 40px;

    }
  }
}
