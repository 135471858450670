@import "assets/styles/colors.scss";

.userListContainer {
  padding: 20px;

  .onClickName {
    cursor: pointer;
    margin: 0;

    &:hover {
      text-decoration: underline;
    }
  }

  .eyeIcon {
    color: $primary-text-color;
    cursor: pointer;
  }

  .amount {
    p {
      margin: 0px;
    }
  }

  :global(.ant-table-title) {
    padding: 0px 20px;
  }

  .customerTable {
    margin: 10px 0px;


  }

  .searchBoxContainer {
    padding: 10px 20px;
  }

  .actions {
    display: flex;
    flex-direction: row;
    gap: 20px;

    img {
      cursor: pointer;
      width: 15px;
    }

    div {
      display: flex;
      flex-direction: row;

      gap: 5px;

      button {
        padding: 5px 10px;
        font-size: 10px;
        color: $secondary-btn-color;
        background-color: $secondary-btn-background-color;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .searchBoxContainer {
      input {
        width: 250px !important;
      }
    }
  }
}

.modalHeading {



  .btn {
    min-width: 100%;
  }
}


.modalEmployee {
  width: 650px;
}

.modalOtpVerification {
  // width: 50%;
  height: 80vh;
  background-color: #ffffff;
}

.otpModalContainer {
  text-align: center;
  height: 75vh;
  vertical-align: center;
  justify-content: center;
  display: flex !important;
  flex-direction: column !important;
  // width: 50%;



  h1 {
    font-weight: bolder;
    font-size: 24px;
    color: #1C1C1E;
    line-height: 0px;
  }

  p {
    font-size: 14px;
    color: #9496A8;
    margin-top: 25px;
    line-height: 0px;
  }
}

.verifyOtp {
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #E51837 !important;
  color: #ffffff !important;
  border: $secondary-btn-border;
  border-radius: 10px;
  padding: 22px;
  margin-top: 25px;

  &:hover {
    cursor: pointer;
    background-color: #E51837 !important;
    color: #ffffff !important;
  }
}


.ant-modal-content {
  box-shadow: none;
}

.mb_1 {
  margin-bottom: 30px;
}

// otp code


@media screen and (max-width: 768px) {
  .otp_form_container {
    padding: 30px;
  }
}

.otp_field_container {
  height: 45px;
  width: 100%;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  overflow: hidden;
  border: 1px solid #9FA2AB;
  cursor: pointer;
  margin-top: 25px;
}

.otp_field_container_error {
  height: 45px;
  width: 100%;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  overflow: hidden;
  border: 1px solid red;
  cursor: pointer;
  margin-top: 25px;
}

.otp_field_container_error input {
  border: none !important;
  background-color: #ffffff;
  width: 100%;
  outline: none;
  cursor: pointer;
}

.otp_field_container_error span hr {
  justify-content: center;
  width: 7px;
  border: 1px solid #9FA2AB;
  margin-top: 20px;
  margin-bottom: 1rem;
}

.otp_field_container input {
  border: none !important;
  background-color: #ffffff;
  width: 100%;
  outline: none;
  cursor: pointer;
}

.otp_field_container input:focus {
  border: none !important;
  background-color: #ffffff;
  outline: none !important;
}

.otp_field_container:hover {
  height: 45px;
  width: 100%;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  overflow: hidden;
  border: 1px solid #9FA2AB;
  cursor: pointer;
  margin-top: 25px;
}

.otp_field_container span hr {
  justify-content: center;
  width: 7px;
  border: 1px solid #9FA2AB;
  margin-top: 20px;
  margin-bottom: 1rem;
}

.text_danger {
  color: red;
  margin-left: 0px !important;
  text-align: left;
  margin-top: 0.4rem;
  font-size: 14px;
}