@import "assets/styles/colors.scss";

.userListContainer {
  padding: 20px;

  .onClickName {
    span {
      cursor: pointer;
      color: #e51837;
      margin: 0;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .eyeIcon {
    color: $primary-text-color;
    cursor: pointer;
  }

  .amount {
    p {
      margin: 0px;
    }
  }

  :global(.ant-table-title) {
    padding: 0px 20px;
  }

  .customerTable {
    margin: 10px 0px;
  }

  .searchBoxContainer {
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    gap: 10px;

    button {
      color: $secondary-btn-color;
      background-color: $secondary-btn-background-color;
      border: $secondary-btn-border;
      height: 40px;
      padding: 5px 20px;
    }

    :global(.ant-select-selector) {
      height: 40px;
      padding: 5px 15px;
      color: $secondary-btn-color;
      background-color: #36454f;
      border: $secondary-btn-border;
    }

    :global(.ant-select-arrow) {
      color: white;
    }


  }

  .checkbox_style {
    span span:nth-child(2) {
      border: 1px solid #292D32;
    }
  }

  .selectBoxBatchNo {
    background-color: #007A84;

    :global(.ant-select-selector) {
      height: 40px;
      padding: 5px 15px;
      color: $secondary-btn-color;
      background-color: #007A84;
      border: $secondary-btn-border;
    }
  }

  .selectBoxCarrier {
    background-color: #A6926B;

    :global(.ant-select-selector) {
      height: 40px;
      padding: 5px 15px;
      color: $secondary-btn-color;
      background-color: #A6926B;
      border: $secondary-btn-border;
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    width: 50px;

    img {
      width: 15px;
      cursor: pointer;
    }

    .eyeIconContainer {
      border: 0;
      padding: 0;
      margin: 0;
      min-width: 0;
      min-height: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      button {
        border: 0px !important;
      }
    }
  }
}

.modalAuctionExcel {
  width: 400px;
  margin: auto;

  .auctionUploadViaExcelForm {
    width: 95%;
    margin: auto;

    .btn {
      width: 100%;
      margin: 5px 0px;
    }

    .downloadSampleFile {
      text-decoration: underline;
      text-align: center;
      cursor: pointer;
    }
  }
}

.deleteButtonStyle {
  background-color: #E51837;
  color: #ffffff;
  font-weight: normal;
  border-radius: 0%;
}