@import "assets/styles/colors.scss";

.userListContainer {
  padding: 20px;

  .onClickName {
    cursor: pointer;
    margin: 0;

    &:hover {
      text-decoration: underline;
    }
  }

  .eyeIcon {
    color: $primary-text-color;
    cursor: pointer;
  }

  .amount {
    p {
      margin: 0px;
    }
  }

  :global(.ant-table-title) {
    padding: 0px 20px;
  }

  .customerTable {
    margin: 10px 0px;

    :global(.ant-table-row-expand-icon) {
      border-color: black !important;
      color: black !important;
    }

    :global(.ant-table-row-expand-icon-cell) {
      width: 0% !important;
    }
  }

  .searchBoxContainer {
    padding: 10px 20px;
    gap: 20px;
    align-items: center;

    button {
      color: $secondary-btn-color;
      background-color: $secondary-btn-background-color;
      border: $secondary-btn-border;
    }
  }

  .expandedRow {
    h6 {
      font-size: 17px;
    }
    .modelsContainer {
      gap: 20px;
      width: auto;
      border: 1px dotted lightgrey;

      p {
        text-align: left;
        margin: 0;
      }
    }
  }

  .gradesTable {
    margin: 10px 0px;
    :global(.ant-table-row-expand-icon) {
      border-color: black !important;
      color: black !important;
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    width: 50px;

    img {
      width: 15px;
      cursor: pointer;
    }
  }


  @media screen and (max-width: 768px) {
    .searchBoxContainer {
      input {
        width: 250px !important;
      }
    }
  }
}

.gradeModal {
  width: 450px;
  margin: auto;
}

.categoryModal {
  width: 450px;

  .manageCategoriesModalForm {
    width: 95%;
    margin: auto;

    :global(.ant-form-item) {
      margin: 15px 0px;
    }

    .fileContainer {
      width: 50%;
      margin: 20px 0px;
      margin: auto;
    }

    .btn {
      width: 100%;
    }
  }
}
