@import "assets/styles/colors.scss";

.rolesFormContainer {
  .form {
    margin: 50px;

    .checkBoxRow {
      flex-direction: column;
      gap: 5px;
      margin: 25px 0px;

      :global(.ant-checkbox-wrapper) {
        color: $primary-text-color;
        font-weight: bold;

        span {
          font-weight: bold;
          font-size: $primary-text-font-size;
        }

        h5 {
          font-size: $primary-heading-font-size;
          font-weight: bold;
          color: $primary-text-color;
        }
      }
    }
  }
}
