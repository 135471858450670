@import "assets/styles/colors.scss";

.userListContainer {
  padding: 20px;

  .onClickName {
    span {
      cursor: pointer;
      color: #e51837;
      margin: 0;

      &:hover {
        text-decoration: underline;
      }
    }
  }


  .eyeIcon {
    color: $primary-text-color;
    cursor: pointer;
  }

  .amount {
    p {
      margin: 0px;
    }
  }

  :global(.ant-table-title) {
    padding: 0px 20px;
  }

  .customerTable {
    margin: 10px 0px;


  }

  .searchBoxContainer {
    display: flex;
    flex-direction: flex-end;
    align-items: center;
    gap: 10px;

  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;

    img {
      cursor: pointer;
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 5px;

      button {
        padding: 5px 10px;
        font-size: 10px;
        color: $secondary-btn-color;
        background-color: $secondary-btn-background-color;
      }
    }
  }

  .selectBoxContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;

    button {
      color: $secondary-btn-color;
      background-color: $secondary-btn-background-color;
      border: $secondary-btn-border;
      height: 40px;
      padding: 5px 20px;
    }

    .btn {
      background-color: #B2BEB5 !important;
      color: #000000 !important;
      font-weight: normal;
      padding: 0 40px;
      border-radius: 0px;
      cursor: pointer;
    }

    .selectBoxHighestBids {
      background-color: #7393B3;

      :global(.ant-select-selector) {
        height: 40px;
        padding: 5px 15px;
        background-color: #A6926B;
        border: $secondary-btn-border;
      }
    }

    .selectBoxCarrier {
      background-color: #7393B3;

      :global(.ant-select-selector) {
        height: 40px;
        padding: 5px 15px;
        background-color: #007A84;
        border: $secondary-btn-border;
      }
    }

    .selectBoxBatchNo {
      background-color: #7393B3;

      :global(.ant-select-selector) {
        height: 40px;
        padding: 5px 15px;
        background-color: #7393B3;
        border: $secondary-btn-border;
      }
    }

    :global(.ant-select-selector) {
      height: 40px;
      padding: 5px 15px;
      color: $secondary-btn-color;
      background-color: #36454F;
      border: $secondary-btn-border;
    }

    :global(.ant-select-arrow) {
      color: white;
    }

    :global(.ant-select-selection-placeholder) {
      color: #ffffff;
    }
  }



  @media screen and (max-width: 768px) {
    .searchBoxContainer {
      input {
        width: 250px !important;
      }
    }
  }
}

.modalContainer {
  width: 400px;
  margin: auto;


  .modalFormContainer {
    width: 100%;
    margin: auto;


    .btn {
      width: 100%;
      margin: 0px 0px;
      padding: 18px 30px;
      font-weight: normal;
    }

    .downloadSampleFile {
      text-decoration: underline;
      text-align: center;
      cursor: pointer;
    }
  }
}