$primary-text-color: #888888;
$primary-heading-color: #888888;
$primary-heading-font-size: 18px;
$primary-text-font-size: 16px;
$primary-text-background: #f0f0f0;
$primary-number-color: #000000;


$primary-btn-color: #ffffff;
$primary-btn-background-color: #000000;
$secondary-btn-background-color: #e51837;
$secondary-btn-color: #ffffff;
$secondary-btn-border: none;

$primary-input-border: #000000;

$pimary-page-bg: white;

$primary-card-bg: #f4f4f4;
$primary-card-border-radius: 10px;
$secondary-card-border-color: #f0f0f0;
$secondary-card-bg: #f4f4f4;
$third-card-bg: #f4f4f4;

$primary-border-color: #f0f0f0;