@import "assets/styles/colors.scss";

.userListContainer {
  padding: 20px;

  .onClickName {
    p {
      cursor: pointer;
      font-weight: bold;
      color: $primary-heading-color;
      margin: 0;

      &:hover {
        text-decoration: underline;
      }
    }
    span {
      color: $primary-text-color;
    }
  }

  .eyeIcon {
    color: $primary-text-color;
    cursor: pointer;
  }

  .amount {
    p {
      margin: 0px;
    }
  }

  :global(.ant-table-title) {
    padding: 0px 20px;
    text-align: center !important;
  }

  .customerTable {
    margin: 10px 0px;

    :global(.ant-table-thead) {
      text-align: left;
      :global(.ant-table-cell) {
        text-align: left;
      }
    }

    :global(.ant-table-cell) {
      text-align: left;
    }

    :global(.ant-table-cell) {
      text-align: left;
    }

    :global(.ant-table-row-expand-icon) {
      border-color: black !important;
      color: black !important;
    }

    :global(.ant-table-row-expand-icon-cell) {
      width: 0% !important;
    }
  }

  .searchBoxContainer {
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    gap: 10px;

    button {
      color: $secondary-btn-color;
      background-color: $secondary-btn-background-color;
      border: $secondary-btn-border;
      height: 40px;
      padding: 5px 20px;
    }

    .uploadViaExcel {
      color: $secondary-btn-color;
      background-color: #36454f;
      border: $secondary-btn-border;
      height: 40px;
      padding: 5px 20px;
    }
    :global(.ant-select-selector) {
      height: 40px;
      padding: 5px 15px;
      color: $secondary-btn-color;
      background-color: #36454f;
      border: $secondary-btn-border;
    }
    :global(.ant-select-arrow) {
      color: white;
    }
  }


  .expandedRow {
    h6 {
      font-size: 17px;
    }
    .modelsContainer {
      gap: 20px;
      width: auto;
      border: 1px dotted lightgrey;

      p {
        text-align: left;
        margin: 0;
      }
    }

    
  }
}

.modalAuctionExcel {
  width: 400px;
  margin: auto;

  .auctionUploadViaExcelForm {
    width: 95%;
    margin: auto;
    .btn {
      width: 100%;
      margin: 5px 0px;
    }
  }

  .downloadSampleFile {
    text-decoration: underline;
    text-align: center;
    cursor: pointer;
  }
}

.modelModal {
  width: 400px;
  margin: auto;
}


.actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  width: 50px;

  img {
    width: 15px;
    cursor: pointer;
  }
}

.mb_1{
  margin-bottom: 30px;
}

.error_message_margin {
  margin-bottom: 10px; /* Adjust the margin-bottom as needed */
}
.ant-form-item-explain {
  margin-top: 5px; /* Adjust the margin as needed */
  color: blue; /* Customize the color */
}
